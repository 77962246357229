import React, { Component } from 'react'

import SEO from '../../components/SEO/SEO'
import Layout from '../../Layout/Layout'
import Hero from '../../components/Hero/Hero'
import Header from '../../components/Header/Header'
import Subheader from '../../components/Subheader/Subheader'
import Container from '../../components/Container/Container'
import BlogTile from '../../components/BlogTile/BlogTile'

import ImageHero from '../../assets/images/zwyzka-teleskopowa-agata-meble-szyldy-reklamowe.jpg'

import Image4 from '../../assets/images/thumb5.jpg'
import Image3 from '../../assets/images/thumb1.jpg'
import Image2 from '../../assets/images/sprzet/socage-da328.jpg'
import Image1 from '../../assets/images/thumb3.jpg'

class BlogPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Blog | Wynajem zwyżki, podnośnika koszowego i nożycowego w Rzeszowie i okolicach"
          keywords={['blog', 'wynajem zwyzki rzeszow', 'zwyzka stalowa wola', 'podnosnik tarnobrzeg', 'wynajem maszyn budowlanych mielec', 'holowanie podkarpackie', 'autopomoc', 'zwyzka lublin']}
        />
        <Hero image={ImageHero}>
          <Header>Blog</Header>
          <Subheader />
        </Hero>
        <Container columns={2}>
          <BlogTile to="/blog/rozszerzamy-zasieg-swiadczenia-naszych-uslug" image={Image4} alt="Rzeszów w województwie podkaprackiem, oraz Tarnów w województwie małopolskim" title="Rozszerzamy zasięg świadczenia naszych usług" />
          <BlogTile to="/blog/jak-skutecznie-wynajac-zwyzke-lub-podnosnik-koszowy" image={Image3} alt="Skuteczne wynajecie zwyzki koszowej na terenie Rzeszowa" title="Jak skutecznie wynająć zwyżkę lub podnośnik koszowy" />
          <BlogTile to="/blog/nowy-podnosnik-w-naszej-ofercie-28m-wysokosci-roboczej" image={Image2} alt="Socage DA-328" title="Nowy podnośnik w naszej ofercie. 28m wysokości roboczej!" />
          <BlogTile to="blog/bezpieczenstwo-w-trakcie-pracy-na-wysokosci-5-najwazniejszych-wskazowek" image={Image1} alt="Zwyżka stojąca przy bloku mieszkalnym, operator w koszu na wysokości dachu" title="Bezpieczeństwo w trakcie pracy na wysokości. 5 najważniejszych wskazówek." />
        </Container>
      </Layout>
    )
  }
}

export default BlogPage
