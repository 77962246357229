import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledEqImage = styled(Link)`
  position: relative;
  margin: 10px 0;
`

const StyledEqImageHolder = styled.figure`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const StyledTitle = styled.h3`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: rgba(0,0,0,0.5);
  color: white;
`

const EqImage = ({ image, alt, title, to }) => (
  <StyledEqImage to={to}>
    <StyledEqImageHolder>
      <Image src={image} alt={alt} />
    </StyledEqImageHolder>
    <StyledTitle>{title}</StyledTitle>
  </StyledEqImage>
)

EqImage.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
}

export default EqImage
